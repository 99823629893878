import * as React from "react"
import {Page} from "../components/layouts/Page";
import {CenteredCode} from "../components/CenteredCode";

// markup
const IndexPage = () => (
    <Page>
        <CenteredCode>mikalajunas.dev</CenteredCode>
    </Page>
)

export default IndexPage
